// src/components/company/container/CompanyMain.js

import React from 'react';
import EmojiGroup from '../../landing/container/emoji/EmojiGroup';

const CompanyMain = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center items-center px-[8%] mt-[15%]">
        <div className="max-w-[1440px] w-full flex flex-col md:flex-row justify-between">
          {/* 왼쪽 텍스트 */}
          <div className="flex flex-col mb-8 mt-24 md:mt-0 md:mb-0 md:mr-4">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900">
              <span className="text-[#D00070]">SlopeX</span>celerity
            </h1>
            <h2 className="text-xl md:text-xl font-bold text-gray-900 mt-4 md:mt-6">
            주식회사 <span className='text-[#D00070] font-extrabold'>슬로프 엑셀러리티</span>는 <br/>
            기초공학교육을 위한<br/>
            다양한 프로젝트에 도전합니다.
              <span className="inline-block">
                <span role="img" aria-label="gear">
                  ⚙️
                </span>
              </span>
            </h2>
          </div>

          {/* 오른쪽 텍스트 */}
          <div className="flex flex-col mt-8 md:mt-0 text-right md:text-right">
            <p className="text-gray-600 mb-1">학교/학원/기관 문의</p>
            <a href="##" className="text-[#D00070] font-bold hover:underline mb-4 md:mb-6">
              최대 6개월 교육 커리큘럼 보유 👾
            </a>
            <p className="text-gray-600 mb-1">기초역량 테스트</p>
            <a href="##" className="text-[#D00070] font-bold hover:underline">
              나의 기초역량 평가는? 👻
            </a>
          </div>
        </div>
      </div>

      {/* EmojiGroup 컴포넌트 추가 */}
      <div className='mb-24'>
        <EmojiGroup />
      </div>
    </div>
  );
};

export default CompanyMain;