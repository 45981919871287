// src/FirebaseAuthListener.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from './Firebase';
import { setUser, clearUser } from './features/authSlice';

const FirebaseAuthListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Firebase 사용자 객체에서 필요한 데이터만 추출하여 상태로 전달
        dispatch(setUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        }));
      } else {
        dispatch(clearUser());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return null;
};

export default FirebaseAuthListener;