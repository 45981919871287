import React from 'react';
import TileContainer from './tileComponents/TileContainer'; // TileContainer 컴포넌트
import Tile from './tileComponents/Tile'; // Tile 컴포넌트
import blockImage01 from '../../../../assets/block01.gif';
import blockImage02 from '../../../../assets/block02.gif';

const BlockContents = () => {
  // 타일 데이터
  const tilesData = [
    {
      title1: '초급자도 할 수 있는 블록코딩 테스트 Level1',
      title2: '제한시간 40분',
      description:
        '개발중입니다.',
      img: blockImage01,
      title1Color: '#fff',
      title2Color: '#fff', // 강조 색상
      descriptionColor: '#fff',
      width: 'md:w-[45%]',
      height: 'md:h-auto',
      mobileWidth: 'w-[600px]',
      mobileHeight: 'h-auto',
    },
    {
      title1: '조금 더 어려운 블록코딩 테스트 Level2',
      title2: '제한시간 1시간',
      description:
        '개발중입니다.',
      img: blockImage02,
      title1Color: '#fff',
      title2Color: '#fff', // 강조 색상
      descriptionColor: '#fff',
      width: 'md:w-[45%]',
      height: 'md:h-auto',
      mobileWidth: 'w-[600px]',
      mobileHeight: 'h-auto',
    },
  ];

  // 타일들을 렌더링
  const tiles = tilesData.map((tile, index) => (
    <Tile
      key={index}
      title1={tile.title1}
      title2={tile.title2} // 강조 텍스트를 title2로 사용
      description={tile.description}
      title1Color={tile.title1Color}
      title2Color={tile.title2Color}
      descriptionColor={tile.descriptionColor}
      img={tile.img}
      width={tile.width}
      height={tile.height}
      mobileWidth={tile.mobileWidth}
      mobileHeight={tile.mobileHeight}
    />
  ));

  return (
    <div className="relative w-full py-8">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4">
          <span className="text-[#D00070]">역량 확인을 위한</span>
          <br />
          블록코딩 테스트
        </h2>
        {/* <a href="##" className="text-sm hover:underline text-left md:text-right">
          테스트가 너무 어렵다면?
          <br />
          <span className="text-[#D00070] font-bold">코치에게 도움구하기 🤩</span>
        </a> */}
      </div>

      {/* 타일 컨테이너 */}
      <TileContainer tiles={tiles} containerId="block-contents-scroll" />
    </div>
  );
};

export default BlockContents;