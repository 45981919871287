// src/components/skeleton/Skeleton.js
import React from 'react';
import skeletonImage from '../../assets/skeleton.png'; // 이미지 경로를 실제 위치에 맞게 수정
import './skeleton.css'; // CSS 파일 임포트

const Skeleton = () => (
  <div className="flex justify-center items-center h-screen"> {/* 화면 중앙 정렬을 위한 flex 컨테이너 */}
    <div className="pulse-animation">
      <img src={skeletonImage} alt="Loading..." className="h-12 w-12" /> {/* 이미지 크기를 50x50px로 설정 */}
    </div>
  </div>
);

export default Skeleton;