// {
//   title1: '기획, GPT, Figma 기초 배우기 👾',
//   title2: 'AI + 웹 개발 따라하기',
//   description:
//     '소프트웨어 구조만 알면,\n누구나 생성형 AI를 이용하여\nWeb/App 개발이 가능합니다.',
//   img: best06,
//   title1Color: '#027400',
//   title2Color: '#027400',
//   descriptionColor: '#000',
//   width: 'md:w-[400px]',
//   height: 'md:h-[500px]',
//   mobileWidth: 'w-[310px]',
//   mobileHeight: 'h-[450px]',
// },
// {
//   title1: '실무정복 🚩',
//   title2: 'GPT + 파이썬 프로그래밍',
//   description: '프로젝트 위주 강의',
//   img: bestBack01,
//   title1Color: '#027400',
//   title2Color: '#027400',
//   descriptionColor: '#000',
//   width: 'md:w-[400px]',
//   height: 'md:h-[500px]',
//   mobileWidth: 'w-[310px]',
//   mobileHeight: 'h-[450px]',
// },

import React from 'react';
import TileContainer from './tileComponents/TileContainer';
import Tile from './tileComponents/Tile';
import bestBack01 from '../../../../assets/bestBack01.png';
import bestBack02 from '../../../../assets/bestBack02.gif';
import bestBack03 from '../../../../assets/bestBack03.png';
import bestBack04 from '../../../../assets/bestBack04.png';

const BestContents = () => {
  // 타일 데이터
  const tilesData = [
    {
      title1: '코딩을 할 줄 몰라도 시작가능 🔥',
      title2: '생성형AI + MS 오피스',
      description: 'GPT, Copilot',
      img: bestBack01,
      title1Color: '#9A0900',
      title2Color: '#000',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
    },
    {
      title1: '개발자를 꿈꾸는 학습자를 위한 ⭐️',
      title2: '파이썬 기초 프로그래밍',
      description: '컴퓨터과학 전공수업 A+ 수준까지',
      img: bestBack02,
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
    },
    {
      title1: 'OpenAI API 정복하기 ⛰️',
      title2: 'AI 서비스 개발 따라하기',
      description: 'API 사용법 + 챗봇 프로젝트',
      img: bestBack03,
      title1Color: '#00069A',
      title2Color: '#00069A',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
    },
    {
      title1: '재택근무, 탄력근무가 가능한 🧑🏻‍💻',
      title2: '01CS 전문코치 육성과정',
      description: 'SlopeXcelerity 전문코치 육성',
      img: bestBack04,
      title1Color: '#D00070',
      title2Color: '#000',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
    },
  ];

  // 타일들을 렌더링
  const tiles = tilesData.map((tile, index) => (
    <Tile
      key={index}
      title1={tile.title1}
      title2={tile.title2}
      description={tile.description}
      title1Color={tile.title1Color}
      title2Color={tile.title2Color}
      descriptionColor={tile.descriptionColor}
      img={tile.img}
      backgroundColor={tile.backgroundColor}
      width={tile.width} // 데스크탑 너비 적용
      height={tile.height} // 데스크탑 높이 적용
      mobileWidth={tile.mobileWidth} // 모바일 너비 적용
      mobileHeight={tile.mobileHeight} // 모바일 높이 적용
    />
  ));

  return (
    <div className="relative w-full py-8">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4">
          현재 진행중인 <br />
          <span className="text-[#D00070]">01CS Class</span>
        </h2>
      </div>
      <TileContainer tiles={tiles} containerId="tile-scroll" />
    </div>
  );
};

export default BestContents;