import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // 추가
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../Firebase';
import { fetchSignInMethodsForEmail, createUserWithEmailAndPassword } from 'firebase/auth';

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailDuplicateError, setEmailDuplicateError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');

  const navigate = useNavigate(); // 추가

  // 이메일 유효성 검사
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // 비밀번호 유효성 검사
  const validatePassword = (password) => {
    const re = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return re.test(password);
  };

  // 핸드폰 번호 정규화 (공백과 하이픈 제거)
  const normalizePhoneNumber = (phone) => {
    return phone.replace(/[ -]/g, '');
  };

  // 폼 유효성 검사
  useEffect(() => {
    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);
    const isPasswordMatch = password === confirmPassword;
    const isPhoneNumberValid = phoneNumber.length >= 10; // 전화번호 유효성 검사
    const isNameValid = name.trim().length > 0; // 이름이 입력되었는지 검사

    setEmailError(isEmailValid || !email ? '' : '유효한 이메일 주소가 아닙니다.');
    setPasswordError(isPasswordValid || !password ? '' : '비밀번호는 8자 이상이며 특수문자를 포함해야 합니다.');
    setConfirmPasswordError(isPasswordMatch || !confirmPassword ? '' : '비밀번호가 일치하지 않습니다.');
    setIsFormValid(isEmailValid && isPasswordValid && isPasswordMatch && isPhoneNumberValid && isNameValid);
  }, [email, password, confirmPassword, phoneNumber, name]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailDuplicateError(''); // 이메일을 변경하면 중복 오류 메시지 초기화
  };

  // 회원가입 처리
  const handleSignup = () => {
    if (isFormValid) {
      // 이메일 중복 검사
      fetchSignInMethodsForEmail(auth, email)
        .then((signInMethods) => {
          if (signInMethods.length === 0) {
            // 이메일이 사용되지 않았으므로 회원가입 진행
            createUserWithEmailAndPassword(auth, email, password)
              .then(async (userCredential) => {
                // 회원가입 성공 시 Firestore에 사용자 정보 저장
                const user = userCredential.user;
                await setDoc(doc(db, "users", user.uid), {
                  email, name, phoneNumber
                  // 여기에 추가적인 사용자 정보를 저장할 수 있습니다
                });

                // 여기에 성공 메시지 또는 추가 처리 로직을 추가할 수 있습니다
                alert('01CS의 세상에 오신걸 환영합니다.');
                navigate('/login');
              })
              .catch((error) => {
                // 회원가입 오류 처리
                console.error('Signup error', error);
                alert('회원가입 중 오류가 발생했습니다: ' + error.message);
              });
          } else {
            setEmailDuplicateError('이미 사용 중인 이메일 주소입니다.');
          }
        })
        .catch((error) => {
          console.error('Email check error', error);
        });
    }
  };

  return (
    <div className="container mx-auto p-4 py-4 md:py-48">
      <div className="flex flex-col md:flex-row justify-center items-center px-[5%]">
        <div className="max-w-[1440px] w-full flex flex-col md:flex-row justify-between">
          {/* 왼쪽 텍스트 */}
          <div className="flex flex-col mb-8 mt-24 md:mt-0 md:mb-0 md:mr-4">
            <h1 className="text-xl md:text-3xl font-bold text-gray-900">
              적응형 학습이 탑재된<span className="text-[#D00070]"> 강력한 LMS로</span>
            </h1>
            <h2 className="text-xl md:text-3xl font-bold text-gray-900 mt-4 md:mt-6">
              언제 어디서든 기초공학 공부를&nbsp;&nbsp;
              <span className="inline-block">
                <span role="img" aria-label="gear">
                  ⚙️
                </span>
                <span role="img" aria-label="heart">
                  🧑🏻‍💻
                </span>
              </span>
            </h2>
          </div>

          {/* 오른쪽 텍스트 */}
          <div className="flex flex-col mt-8 md:mt-0 text-right md:text-right">
            <p className="text-gray-600 mb-1">사용자의 학습 데이터 분석</p>
            <a href="##" className="text-[#D00070] font-bold hover:underline mb-4 md:mb-6">
              01CS의 LMS 알아보기 👾
            </a>
            <p className="text-gray-600 mb-1">공학전문 코치와 함께</p>
            <a href="##" className="text-[#D00070] font-bold hover:underline">
              인터랙티브 + 시각화 👻
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center pt-24 mb-24">
        <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 mb-6">
          <span className="text-[#D00070]">01CS 회원가입</span>
        </h1>
        <input
          type="email"
          placeholder="이메일"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2 border-[#D00070] focus:outline-none focus:border-[#D00070]"
          value={email}
          onChange={handleEmailChange}
        />
        {emailDuplicateError && <p className="text-red-500 text-xs">{emailDuplicateError}</p>}
        {email && !validateEmail(email) && <p className="text-red-500 text-xs">{emailError}</p>}

        <input
          type="password"
          placeholder="비밀번호"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2 border-[#D00070] focus:outline-none focus:border-[#D00070]"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {password && !validatePassword(password) && <p className="text-red-500 text-xs">{passwordError}</p>}

        <input
          type="password"
          placeholder="비밀번호 확인"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2 border-[#D00070] focus:outline-none focus:border-[#D00070]"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {confirmPassword && password !== confirmPassword && <p className="text-red-500 text-xs">{confirmPasswordError}</p>}

        {/* 핸드폰 번호 입력 */}
        <input
          type="tel"
          placeholder="핸드폰 번호"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2 border-[#D00070] focus:outline-none focus:border-[#D00070]"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(normalizePhoneNumber(e.target.value))}
        />

        {/* 이름 입력 */}
        <input
          type="text"
          placeholder="이름"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2 border-[#D00070] focus:outline-none focus:border-[#D00070]"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {/* 회원가입 버튼 크기 조정 */}
        <button
          className={`btn mt-4 px-10 py-4 text-white text-lg transition duration-300 ${
            isFormValid 
              ? 'bg-[#D00070] hover:bg-[#c20066] hover:opacity-80' 
              : 'bg-[#D00070] opacity-40 cursor-not-allowed'
          }`} 
          onClick={handleSignup}
          disabled={!isFormValid}
        >
          회원가입 완료
        </button>
      </div>
    </div>
  );
};

export default SignupPage;