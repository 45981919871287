import React, { useState } from 'react';

const notices = [
  { id: 1, title: '24-10-10 알파버전 오픈 예정', date: '2024-09-16', content: '알파버전에서는 LMS의 기본적인 기능을 사용할 수 있도록 합니다. 교육자료는 더이상 제공해드리지 않습니다.' },
  { id: 2, title: 'Ver.Pre-Alpha OPEN 🎉', date: '2024-09-01', content: '줌 강의 및 교육자료 배포를 포함합니다.' },
  // { id: 3, title: '👾', date: '2024-09-05', content: '👾' },
  // { id: 4, title: '🏗️', date: '2024-09-05', content: '🏗️' },
  // { id: 5, title: '🚩', date: '2024-09-05', content: '🚩' },
  // { id: 6, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
];

const TabB = () => {
  const [activeNotices, setActiveNotices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleNoticeClick = (id) => {
    setActiveNotices((prevActiveNotices) =>
      prevActiveNotices.includes(id)
        ? prevActiveNotices.filter((noticeId) => noticeId !== id)
        : [...prevActiveNotices, id]
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNotices = notices.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(notices.length / itemsPerPage);

  const handlePageClick = (page) => {
    if (typeof page === 'number') {
      setCurrentPage(page);
    } else {
      setCurrentPage((prevPage) => {
        let newPage = page === 'next' ? prevPage + 1 : prevPage - 1;
        if (newPage < 1) newPage = 1;
        if (newPage > totalPages) newPage = totalPages;
        return newPage;
      });
    }
  };

  return (
    <div className="max-w-[380px] md:max-w-[800px] mx-auto mt-12 space-y-6">
      {currentNotices.map((notice) => (
        <div key={notice.id} className="border-b pb-4">
          <h3
            className={`text-sm md:text-xl font-bold cursor-pointer flex justify-between items-center ${
              activeNotices.includes(notice.id) ? 'text-[#D00070]' : 'text-[#333]'
            }`}
            onClick={() => handleNoticeClick(notice.id)}
          >
            {/* No. 부분에 고정 너비 설정 */}
            <span className="w-[45px] md:w-[100px] tracking-[-0.1em] md:tracking-normal">{`No.${notice.id}`}</span>
            
            {/* 제목 부분에 flex-grow로 남은 공간을 차지 */}
            <span className="flex-1 px-2 md:px-12 text-left">{notice.title}</span>
            
            {/* 날짜 부분에 고정 너비 설정 */}
            <span className="w-[85px] md:w-[150px] text-right tracking-[-0.1em] md:tracking-normal text-gray-600">{notice.date}</span>
          </h3>
          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out ${
              activeNotices.includes(notice.id) ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            <p className="text-xs md:text-base text-gray-600 mt-4">{notice.content}</p>
          </div>
        </div>
      ))}

      {/* Pagination */}
      <div className="flex justify-center mt-4 space-x-2">
        {/* Previous page button */}
        <button
          className="px-3 py-1 rounded bg-gray-200 text-gray-600"
          onClick={() => handlePageClick('prev')}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>

        {/* Page number buttons */}
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            className={`px-3 py-1 rounded ${
              currentPage === index + 1 ? 'bg-[#D00070] text-white' : 'bg-gray-200 text-gray-600'
            }`}
            onClick={() => handlePageClick(index + 1)}
          >
            {index + 1}
          </button>
        ))}

        {/* Next page button */}
        <button
          className="px-3 py-1 rounded bg-gray-200 text-gray-600"
          onClick={() => handlePageClick('next')}
          disabled={currentPage === totalPages}
        >
          {'>'}
        </button>
      </div>
    </div>
  );
};

export default TabB;