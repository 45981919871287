import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import AuthBtn from './AuthBtn';
import TopBanner from './TopBanner';

const Header = ({ toggleMobileMenu }) => {
  const [isTopBannerVisible, setIsTopBannerVisible] = useState(true);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isScrolledPastBanner, setIsScrolledPastBanner] = useState(false);
  const [headerOpacity, setHeaderOpacity] = useState(1);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' }); // 최상단으로 스크롤 이동
  };

  const handleTopBannerClose = () => {
    setIsTopBannerVisible(false);
  };

  const handleLinkClick = (path) => {
    navigate(path); // 경로 이동
    window.scrollTo({ top: 0, behavior: 'smooth' }); // 최상단으로 스크롤 이동
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // 스크롤이 일정 범위 이상 내려갔는지 체크 (TopBanner 영역을 넘으면 true)
      if (currentScrollY > 60) {
        setIsScrolledPastBanner(true);
      } else {
        setIsScrolledPastBanner(false);
      }

      // 스크롤 이동에 따라 헤더의 투명도 변경
      if (currentScrollY === 0) {
        setHeaderOpacity(1); // 최상단일 때 투명도 1
      } else {
        setHeaderOpacity(0.75); // 스크롤 시 헤더 약간 투명
      }

      if (currentScrollY > lastScrollY && currentScrollY > 60) {
        setIsHeaderVisible(false); // 스크롤을 내리면 헤더 숨김
      } else if (currentScrollY < lastScrollY) {
        setIsHeaderVisible(true); // 스크롤을 올리면 다시 표시
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      {isTopBannerVisible && <TopBanner onClose={handleTopBannerClose} />}

      <header
        style={{ opacity: headerOpacity }} // 투명도 적용
        className={`bg-white h-[65px] flex items-center fixed top-0 w-full z-[1000] transition-transform duration-500 ease-in-out ${
          isHeaderVisible ? 'translate-y-0' : '-translate-y-full'
        } ${isScrolledPastBanner || !isTopBannerVisible ? 'top-0' : 'top-[55px]'}`}
      >
        <div className="max-w-[1160px] w-full flex items-center justify-between px-4 mx-auto">
          {/* 로고 */}
          <div className="flex items-center w-[30%] md:w-[10%]">
            <img
              src={logo}
              alt="01CS"
              className="h-auto w-auto cursor-pointer"
              onClick={handleLogoClick}
            />
          </div>

          {/* 데스크탑용 카테고리와 버튼 */}
          <nav className="hidden md:flex md:flex-1 justify-center gap-8">
            <button className="text-gray-700 hover:text-[#D00070]" onClick={() => handleLinkClick('/company')}>회사소개</button>
            <button className="text-gray-700 hover:text-[#D00070]" onClick={() => handleLinkClick('/class')}>교육자료</button>
            {/* <button className="text-gray-700 hover:text-[#D00070]" onClick={() => handleLinkClick('##')}>제품소개</button> */}
            <button className="text-gray-700 hover:text-[#D00070]" onClick={() => handleLinkClick('/customer')}>고객센터</button>
            {/* <button className="text-gray-700 hover:text-[#D00070]" onClick={() => handleLinkClick('##')}>채용</button> */}
            {/* <a
              href="https://blog.naver.com/slopexcelerity"
              className="text-gray-700 hover:text-[#D00070]"
              target="_blank" // 새 창에서 열기
              rel="noopener noreferrer"
            >
              공식 블로그
            </a> */}
            <button className="text-blue-500 font-extrabold hover:text-blue-900" onClick={() => handleLinkClick('/zoom')}>ZOOM 강의</button>
          </nav>

          {/* AuthBtn 컴포넌트 */}
          <div className="hidden md:flex">
            <AuthBtn />
          </div>

          {/* 모바일 메뉴 아이콘 */}
          <div className="flex md:hidden ml-auto w-auto">
            <button onClick={toggleMobileMenu} className="focus:outline-none">
              {/* SVG로 얇은 햄버거 아이콘 구현 */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={1.5} // 두께를 1로 설정
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;