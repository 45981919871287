import React from 'react';

const Footer = () => {
  return (
    <div className="bg-black text-white py-24 -mt-1">
      <div className="container mx-auto px-6 md:px-12">
        <div className="flex flex-col md:flex-row justify-between items-start">
          {/* Left Column - Large Text */}
          <div className="flex flex-col space-y-2">
            <p>
              <span className='text-4xl font-extrabold text-[#D00070]'>SlopeX</span>
              <span className='text-4xl font-bold'>celerity</span>
            </p>
            <br />
            {/* <p>주식회사 슬로프엑셀러리티</p> */}
            <p>대표 : 조용진</p>
            <p>01cs@01cs.kr</p>
            <p>010-2765-1787</p>
          </div>

          {/* Right Columns - Categories and Education */}
          <div className="flex space-x-8 mt-8 md:mt-0 text-white">
            {/* Center Column - Product Links */}
            <div className="flex flex-col space-y-4">
              <h4 className="text-xl font-bold text-[#D00070]">주요 카테고리</h4>
              <a href="/company" className="hover:text-[#D00070]">회사소개</a>
              <a href="##" className="hover:text-[#D00070]">제품소개</a>
              <a href="/customer" className="hover:text-[#D00070]">고객센터</a>
              <a href="##" className="hover:text-[#D00070]">채용</a>
              <a
                href="https://blog.naver.com/slopexcelerity"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#2DB400]"
              >
                네이버 블로그
              </a>
            </div>

            {/* Right Column - Product Links */}
            <div className="flex flex-col space-y-4">
              <h4 className="text-xl font-bold text-[#D00070]">주요 교육</h4>
              <a href="##" className="hover:text-[#D00070]">00MATH</a>
              <a href="/" className="hover:text-[#D00070]">01CS</a>
              <a href="##" className="hover:text-[#D00070]">04Robo</a>
              <a href="##" className="hover:text-[#D00070]">08Semi</a>
            </div>
          </div>
        </div>

        {/* Bottom Row - Links */}
        <div className="flex justify-left mt-12 space-x-8 text-white">
          <a href="##" className="hover:text-[#D00070]">팀소개</a>
          <a href="##" className="hover:text-[#D00070]">이용약관</a>
          <a href="##" className="hover:text-[#D00070]">개인정보</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;