import React, { useState, useEffect } from 'react';
import dis01 from '../../../../assets/dis01.png';
import dis02 from '../../../../assets/dis02.png';
import dis03 from '../../../../assets/dis03.png';
import DiscountModal from '../modal/DiscountModal'; // 모달 컴포넌트

const DiscountContents = () => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTile, setSelectedTile] = useState(null);

  const tiles = [
    {
      title1: '그룹을 위한 기초공학교육 ⚙️',
      title2: '일일 특강부터 부트캠프까지,\n다양한 공학교육 커리큘럼 보유',
      description: '기초공학교육이 필요한 곳이라면 어디든지, 며칠이든.\n중학생, 고등학생을 위한 부트캠프도.',
      img: dis01,
      color: '#FF5733',
      modalContent: {
        modalTitle: '그룹에게 딱 맞는 교육제공',
        modalDetails: `일일 특강부터 최대 6개월까지 부트캠프 교육이 가능합니다.
                    프로그래밍 기초부터 실무에 필요한 다양한 개발환경까지 배워보세요.
                    다양한 개발자 교육 경험을 바탕으로 설계된 교육 커리큘럼과 01CS의 강력한 LMS는 그룹으로 이용할 때 더 저렴합니다.
                    그룹의 일정에 맞춘 커리큘럼으로 기초공학을 공부해보세요.`,
        modalExtraInfo: '그룹 커리큘럼 문의하기 〉',
        // modalLink: '#'
      },
    },
    {
      title1: '그룹을 위한 교육혜택 🎉',
      title2: 'Peer Learning(동료 학습)의\n학습경험을 온라인에서도',
      description: '학습자의 수준에 맞춘 교육이 가능\n교육은 13세 이상부터 추천드립니다.',
      img: dis02,
      color: '#C70039',
      modalContent: {
        modalTitle: '2명부터 그룹으로',
        modalDetails: '그룹을 만들고, 학생들을 관리할 수 있습니다. 그룹장에게 그룹을 관리할 수 있는 권한을 제공합니다. 그룹장에게는 새로운 수익모델을 만들어드립니다.',
        modalExtraInfo: '그룹 만들기 〉',
        // modalLink: '#'
      },
    },
    {
      title1: '코치님께 드리는 특별한 혜택 ❤️',
      title2: '01CS의 코치님께\n새로운 수익모델을',
      description: '학생수가 많아질수록 더 큰 혜택\n기초공학 교육으로 세상을 이롭게',
      img: dis03,
      color: '#900C3F',
      modalContent: {
        modalTitle: '01CS 코치님이 누릴 수 있는 혜택',
        modalDetails: '학생을 관리하는 코치님께 새로운 수익모델을 제시합니다. 직접 수업을 하진 않더라도, 학생들을 관리하고 질문에 대답하는 정도만으로도 수익을 만들 수 있습니다.',
        modalExtraInfo: '코치 등록하기 〉',
        // modalLink: '#'
      },
    },
  ];

  // 스크롤 상태 감지
  const handleScroll = () => {
    const scrollContainer = document.getElementById('discount-tile-scroll');
    const scrollLeft = scrollContainer.scrollLeft;
    const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < maxScrollLeft);

    if (scrollContainer.scrollWidth <= scrollContainer.clientWidth) {
      setShowButtons(false);
    }
  };

  useEffect(() => {
    const scrollContainer = document.getElementById('discount-tile-scroll');
    scrollContainer.addEventListener('scroll', handleScroll);
    handleScroll(); // 초기 스크롤 상태 확인
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollLeft = () => {
    document.getElementById('discount-tile-scroll').scrollBy({ left: -410, behavior: 'smooth' });
  };

  const scrollRight = () => {
    document.getElementById('discount-tile-scroll').scrollBy({ left: 410, behavior: 'smooth' });
  };

  const openModal = (tile) => {
    setSelectedTile(tile); // 타일 데이터를 저장
    setIsModalOpen(true);  // 모달 열기
  };

  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫기
  };

  return (
    <div className="relative w-full py-32">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4">
          <span className="text-[#D00070]">특별할인</span><br />
          학교/기관/강사를 위한 혜택
        </h2>
      </div>

      <div
        className="relative"
        onMouseEnter={() => {
          const scrollContainer = document.getElementById('discount-tile-scroll');
          if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
            setShowButtons(true);
          }
        }}
        onMouseLeave={() => setShowButtons(false)}
      >
        {/* 좌우 버튼 */}
        {showButtons && (
          <>
            <button
              onClick={scrollLeft}
              className={`absolute top-1/2 left-5 z-10 -translate-y-1/2 w-16 h-16 flex items-center justify-center bg-[#fff]/60 hover:bg-[#fff]/80 rounded-full shadow-lg hidden md:block`}
              disabled={!canScrollLeft}
              style={{
                opacity: canScrollLeft ? 1 : 0,
                transform: canScrollLeft ? 'scale(1)' : 'scale(0.9)',
                transition: 'opacity 0.8s ease-in-out, transform 0.5s ease-in-out',
              }}
            >
              <span className="text-3xl text-black" style={{ fontWeight: '900' }}>
                〈
              </span>
            </button>

            <button
              onClick={scrollRight}
              className={`absolute top-1/2 right-5 z-10 -translate-y-1/2 w-16 h-16 flex items-center justify-center bg-[#fff]/60 hover:bg-[#fff]/80 rounded-full shadow-lg hidden md:block`}
              disabled={!canScrollRight}
              style={{
                opacity: canScrollRight ? 1 : 0,
                transform: canScrollRight ? 'scale(1)' : 'scale(0.9)',
                transition: 'opacity 0.8s ease-in-out, transform 0.5s ease-in-out',
              }}
            >
              <span className="text-3xl text-black ml-1" style={{ fontWeight: '900' }}>
                〉
              </span>
            </button>
          </>
        )}

        {/* 스크롤 가능한 타일 컨테이너 */}
        <div id="discount-tile-scroll" className="flex justify-start overflow-x-auto no-scrollbar w-full px-2 py-10">
          {tiles.map((tile, index) => (
            <div
              key={index}
              className="w-[310px] h-[450px] md:w-[400px] md:h-[500px] rounded-2xl shadow-lg mx-4 flex-shrink-0 transform transition-transform duration-300 hover:scale-105 cursor-pointer"
              onClick={() => openModal(tile)} // 타일 클릭 시 모달 열기
            >
              <div className="p-4">
                <h3 className="text-sm font-bold" style={{ color: tile.color }}>{tile.title1}</h3>
                <h3 className="text-xl font-extrabold" style={{ whiteSpace: 'pre-line' }}>{tile.title2}</h3>
                <p className="text-gray-500 mt-2" style={{ whiteSpace: 'pre-line' }}>{tile.description}</p>
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={tile.img}
                  alt={tile.title2}
                  className="w-[300px] h-auto object-cover rounded-2xl"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 모달 컴포넌트 */}
      {selectedTile && (
        <DiscountModal
          isOpen={isModalOpen}
          onClose={closeModal}
          imgSrc={selectedTile.img}
          title1={selectedTile.title1}
          title2={selectedTile.title2}
          description={selectedTile.description}
          modalContent={selectedTile.modalContent} // 타일에 따라 다른 내용 전달
        />
      )}
    </div>
  );
};

export default DiscountContents;