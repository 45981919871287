import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import AuthBtn from './AuthBtn';

const MobileMenu = ({ isOpen, closeMenu }) => {

  // 페이지 이동 시 최상단으로 스크롤하는 함수
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // 최상단으로 스크롤
    closeMenu(); // 메뉴 닫기
  };

  return (
    <>
      {/* 어두운 배경 */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[9999]" // 메뉴 바깥을 어둡게 처리
          onClick={closeMenu} // 배경을 클릭하면 메뉴 닫기
        ></div>
      )}

      {/* 모바일 메뉴 */}
      <div
        className={`fixed top-0 right-0 h-full w-[300px] bg-[#D00070] shadow-md transition-transform duration-500 ease-in-out z-[10000] ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* 닫기 버튼 */}
        <button className="absolute top-6 right-8 text-white focus:outline-none" onClick={closeMenu}>
          <FaTimes size={24} />
        </button>

        {/* 메뉴 항목과 사용자 인증 버튼을 포함한 전체 컨테이너 */}
        <div className="flex flex-col justify-between items-center h-full">
          {/* 메뉴 항목 */}
          <nav className="flex flex-col items-center font-extrabold gap-6 mt-36">
            <Link to="/company" className="text-white hover:text-gray-200" onClick={handleLinkClick}>회사소개</Link>
            <Link to="/class" className="text-white hover:text-gray-200" onClick={handleLinkClick}>교육자료</Link>
            {/* <Link to="##" className="text-white hover:text-gray-200" onClick={handleLinkClick}>제품소개</Link> */}
            <Link to="/customer" className="text-white hover:text-gray-200" onClick={handleLinkClick}>고객센터</Link>
            {/* <Link to="##" className="text-white hover:text-gray-200" onClick={handleLinkClick}>채용</Link> */}
            {/* 공식 블로그 추가 */}
            {/* <a 
              href="https://blog.naver.com/slopexcelerity" 
              className="text-white hover:text-gray-200" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={closeMenu} // 메뉴 닫기 함수
            >
              공식 블로그
            </a> */}
            <Link to="/zoom" className="text-blue-300 hover:text-gray-200" onClick={handleLinkClick}>ZOOM 강의</Link>
          </nav>

          {/* 사용자 인증 버튼들 */}
          <div className="mt-auto mb-24 w-full px-6 flex justify-center gap-2"> {/* justify-center 추가 */}
            <AuthBtn
              buttonStyle="border border-white py-2 px-4 rounded-full transition duration-300"
              linkStyle="bg-white text-[#D00070] py-2 px-4 rounded-full border-2 border-transparent animate-pulse transition duration-300"
              closeMenu={closeMenu} // closeMenu 함수 전달
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;