import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa'; // 닫기 버튼 아이콘
import PopupImage from '../../../assets/popupImg.png'; // 이미지 임포트
import Cookies from 'js-cookie'; // 쿠키 관리 라이브러리

const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [doNotShowToday, setDoNotShowToday] = useState(false);

  useEffect(() => {
    // 쿠키 확인: 'popupClosed'가 있으면 팝업을 표시하지 않음
    const popupClosed = Cookies.get('popupClosed');
    if (!popupClosed) {
      // 0.5초 후에 팝업 표시
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 500);

      return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 제거
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false); // 닫기 버튼 클릭 시 팝업 숨김
    if (doNotShowToday) {
      // '오늘은 더이상 열지 않기'가 체크되었으면 쿠키 설정 (1일 동안 유지)
      Cookies.set('popupClosed', 'true', { expires: 1 });
    }
  };

  const handleCheckboxChange = () => {
    setDoNotShowToday(!doNotShowToday); // 체크박스 상태 변경
  };

  if (!isVisible) return null; // 팝업이 보이지 않을 경우 렌더링하지 않음

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[2000]">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-[350px] w-full relative">
        {/* 닫기 버튼 */}
        <button className="absolute top-4 right-4 text-[#D00070] focus:outline-none" onClick={handleClose}>
          <FaTimes size={24} />
        </button>

        {/* 이미지와 텍스트 */}
        <img src={PopupImage} alt="Popup" className="w-full h-auto text-base" />
        <p className="text-center font-bold">모든 기능이 아직 구현되지 않았습니다.<br/>가격도 완전히 정한건 아닙니다.<br/>반드시 부담없는 가격을 측정하겠습니다.</p><br/>
        <p className="text-center font-bold">전세계 누구나 공학의 기초를<br/>재미있게 공부할 수 있는<br/>교육 플랫폼으로 만들어가겠습니다.</p><br/>
        <p className="text-center text-lg font-bold"><span className='text-[#D00070]'>SlopeX</span>celerity<br/>Founder 조용진 올림</p>

        {/* 닫기 버튼 */}
        <button 
          className="mt-8 bg-[#D00070] text-white px-4 py-2 rounded-lg w-full"
          onClick={handleClose}
        >
          닫기
        </button>

        {/* '오늘은 더이상 열지 않기' 체크박스 */}
        <div className="flex items-center mt-4 justify-end">
          <input
            type="checkbox"
            id="do-not-show"
            className="mr-2"
            checked={doNotShowToday}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="do-not-show" className="text-gray-700 text-sm">
            오늘은 더이상 열지 않기
          </label>
        </div>
      </div>
    </div>
  );
};

export default Popup;