/* 
  쿠키 설정으로 닫기 버튼을 눌린 사용자는 다시 뜨지않도록 주의.
  정기구독을 신청한 사람은 안뜨도록.
*/

import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa'; // 닫기 버튼 아이콘

const TopBanner = ({ onClose }) => {
  return (
    <div className="bg-[#D00070] h-[60px] flex justify-center items-center transition-all duration-500">
      <div className="max-w-[1440px] w-full flex justify-center items-center px-4 relative">
        {/* 텍스트와 링크 */}
        <Link to="##" className="text-white text-[16px] md:text-[20px] font-medium">
          {/* 01CS의 교육은 <span className="font-extrabold">월 9,800원.</span> */}
          Ver. Pre-Alpha <span className="font-extrabold">LMS 개발중...</span>
        </Link>

        {/* X 버튼 아이콘 */}
        <button
          className="text-white absolute right-4 mr-[3%] focus:outline-none"
          onClick={onClose}
        >
          <FaTimes size={20} />
        </button>
      </div>
    </div>
  );
};

export default TopBanner;