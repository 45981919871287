import React, { useState } from 'react';

const questions = [
  { id: 1, category: '서비스 전반', question: '01CS는 어떤 서비스인가요?', answer: '컴퓨터 과학(Computer Science) 분야를 공부할 수 있는 서비스입니다. 인터랙티브 + 코드 시각화로 단순히 문법만 배우는게 아니라, 컴퓨터구조/자료구조 등을 이해하면서 컴퓨터 과학이라는 분야의 기초를 단단하게 다질 수 있습니다. 월 8,900원으로 01CS의 모든 교육을 받아보실 수 있습니다.' },
  { id: 2, category: '서비스 전반', question: '00MATH, 04Robo 등 다른 교육도 있나요?', answer: '네 맞습니다. 기초공학교육을 위해서 기초수학부터 공학계열의 다양한 분야에 대해서도 교육을 제공해드리려고 합니다. 우리의 꿈은 누구나 기초공학교육을 받고, 공학기술로 산업의 기반을 탄탄하게 다지는 것입니다.' },
  { id: 3, category: '서비스 전반', question: '무료체험이 가능한가요?', answer: '한 달 무료체험을 제공해드리고 있습니다. 유료로 교육을 제공하지만, 최대한 낮은 가격을 책정하여 누구나 기초공학교육을 들을 수 있도록 하는 것이 우리의 운영정책 중 하나입니다.' },
  { id: 4, category: '기기 이용', question: '모바일에서 이용 가능한가요?', answer: '네, 모바일에서도 가능합니다.' },
  { id: 5, category: '기기 이용', question: 'PC와 연동되나요?', answer: '네, PC와 연동됩니다.' },
  { id: 6, category: '기기 이용', question: '인터넷 연결이 필요한가요?', answer: '네, 필요합니다.' },
];

const TabA = () => {
  const [activeQuestions, setActiveQuestions] = useState([]);

  const handleQuestionClick = (id) => {
    setActiveQuestions((prevActiveQuestions) =>
      prevActiveQuestions.includes(id)
        ? prevActiveQuestions.filter((q) => q !== id)
        : [...prevActiveQuestions, id]
    );
  };

  const groupedQuestions = questions.reduce((acc, curr) => {
    if (!acc[curr.category]) {
      acc[curr.category] = [];
    }
    acc[curr.category].push(curr);
    return acc;
  }, {});

  return (
    <div className="space-y-8 max-w-[380px] md:max-w-[800px] mx-auto">
      {Object.keys(groupedQuestions).map((category) => (
        <div key={category}>
          <h2 className="text-xl font-bold text-[#D00070] mt-12 mb-6">{`[${category}]`}</h2>
          {groupedQuestions[category].map((q) => (
            <div key={q.id} className="border-b pb-6">
              <h3
                className={`text-lg mt-6 font-bold cursor-pointer ${
                  activeQuestions.includes(q.id) ? 'text-[#D00070]' : 'text-[#333]'
                }`}
                onClick={() => handleQuestionClick(q.id)}
              >
                {q.question}
              </h3>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  activeQuestions.includes(q.id) ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <p className="text-gray-600 mt-4">{q.answer}</p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TabA;