// src/pages/ResetPasswordPage.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // useNavigate import
import { auth } from '../../Firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // useNavigate 초기화

  // 이메일 유효성 검사
  const isEmailValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleResetPassword = async () => {
    setLoading(true);
    if (!isEmailValid(email)) {
      setMessage('유효한 이메일 주소를 입력하세요.');
      setLoading(false);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert('비밀번호 재설정 링크가 이메일로 전송되었습니다.');
      navigate('/login'); // 로그인 페이지로 이동
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        setMessage('해당 이메일로 등록된 계정이 없습니다.');
      } else {
        setMessage('이메일 전송에 실패했습니다. 다시 시도해주세요.');
      }
      console.error('Error sending password reset email:', error);
    }
    setLoading(false);
  };

  return (
    <div className="flex justify-center items-center py-20 md:py-36">
      <div className="p-6 bg-white rounded-lg max-w-md w-full mx-4">
        <h2 className="text-2xl font-bold mb-10 text-center">비밀번호 재설정</h2>
        
        <div className="space-y-6">
          <input
            type="email"
            placeholder="이메일"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-[#D00070] rounded-md focus:outline-none focus:border-[#D00070]"
          />
          <button 
            onClick={handleResetPassword} 
            className={`w-full p-2 rounded-md text-white font-bold transition duration-300 ${
              isEmailValid(email) && !loading 
                ? 'bg-[#D00070] hover:bg-[#D00070] hover:opacity-90' 
                : 'bg-[#D00070] opacity-40 cursor-not-allowed'
            }`} 
            disabled={!isEmailValid(email) || loading}
          >
            비밀번호 초기화하기
          </button>
        </div>
        
        {message && <p className="mt-2 text-center text-gray-700">{message}</p>}
  
        <div className="flex justify-between text-sm text-[#D00070] mt-6">
          <Link to="/find-email" className="hover:underline">아이디 찾기</Link>
          <Link to="/signup" className="hover:underline">회원가입</Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
