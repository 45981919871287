import React, { useEffect, useRef, useState } from 'react';

const CompanyCredit = () => {
  const [opacity, setOpacity] = useState(1);
  const [secondOpacity, setSecondOpacity] = useState(0); // 두 번째 텍스트의 초기 투명도
  const [isFixed, setIsFixed] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const offsetTop = containerRef.current.offsetTop;
      const scrollRange = 500; // 첫 번째 텍스트가 사라지는 범위
      const secondScrollRange = 500; // 두 번째 텍스트가 나타나는 범위
      const totalScrollRange = scrollRange + secondScrollRange;

      if (scrollTop > offsetTop && scrollTop < offsetTop + totalScrollRange) {
        // 스크롤 범위 내에서 opacity 계산
        const newOpacity = 1 - (scrollTop - offsetTop) / scrollRange;
        setOpacity(Math.max(newOpacity, 0)); // 최소값을 0으로 설정하여 음수로 가지 않도록 함

        // 두 번째 텍스트 opacity 설정
        const secondTextOpacity = Math.min((scrollTop - offsetTop - scrollRange) / secondScrollRange, 1);
        setSecondOpacity(secondTextOpacity);
      }

      // 두 번째 텍스트가 다 나타났을 때 고정 해제
      if (scrollTop >= offsetTop + totalScrollRange) {
        setIsFixed(false);
      } else if (scrollTop < offsetTop + totalScrollRange) {
        setIsFixed(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // clean up the event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      },
      {
        rootMargin: '0px',
        threshold: 0,
      }
    );

    const currentContainerRef = containerRef.current; // ref 값을 변수에 저장

    if (currentContainerRef) {
      observer.observe(currentContainerRef);
    }

    return () => {
      if (currentContainerRef) {
        observer.unobserve(currentContainerRef);
      }
    };
  }, []);

  return (
    <div className="relative h-[350vh] bg-black" ref={containerRef}> {/* 높이를 늘려서 스크롤 공간 확보 */}
      <div className={`sticky top-0 h-screen w-full ${isFixed ? '' : 'relative'}`}>
        <div
          className={`absolute top-0 left-0 w-full text-center h-screen flex items-center justify-center transition-opacity duration-500`}
          style={{ opacity }}
        >
          <h2 className="text-4xl text-[#fff] font-bold">
            <span className="text-[#D00070]">SlopeX</span>celerity
          </h2>
        </div>
        <div
          className={`absolute top-0 left-0 w-full text-center h-screen flex items-center justify-center transition-opacity duration-500`}
          style={{ opacity: secondOpacity }} // 두 번째 텍스트의 투명도 조절
        >
          <p className="text-lg text-[#fff] font-bold">
            To provide foundational engineering education.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyCredit;