import React, { useState, useEffect } from 'react';
import { auth } from '../../Firebase'; // Firebase 설정 파일 경로에 맞게 수정
import { onAuthStateChanged, signOut } from 'firebase/auth';

const AuthBtn = ({ buttonStyle, linkStyle, closeMenu }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return unsubscribe; // 구독 해제
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      window.location.href = '/'; // 로그아웃 후 메인 페이지로 이동
      if (closeMenu) closeMenu();
    } catch (error) {
      console.error('로그아웃 실패', error);
    }
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (closeMenu) closeMenu();
  };

  return (
    <div className="flex gap-4">
      {user ? (
        <>
          <button
            onClick={handleLogout}
            className={`border border-[#D00070] text-white md:text-[#D00070] py-2 px-5 rounded-full hover:border-[#D00070] hover:text-[#D00070] transition duration-300 ${buttonStyle}`}
          >
            로그아웃
          </button>
          <a
            href="/lms"
            // href="/"
            target="_blank"
            rel="noopener noreferrer"
            className={`bg-white md:bg-[#D00070] text-[#D00070] md:text-[#fff] md:border-[#D00070] font-extrabold py-2 px-5 rounded-full border-2 border-transparent animate-pulse transition duration-300 ${buttonStyle}`}
          >
            LMS 개발중
          </a>
        </>
      ) : (
        <>
          <a
            href="/login"
            className={`border border-[#D00070]/50 text-[#D00070] py-2 px-6 rounded-full hover:border-[#D00070] hover:text-[#D00070] transition duration-300 ${linkStyle}`}
            onClick={handleLinkClick}
          >
            로그인
          </a>
          <a
            href="/signup"
            className={`bg-[#D00070] text-white font-bold py-2 px-6 rounded-full border-2 border-transparent animate-pulse transition duration-300 ${buttonStyle}`}
            onClick={handleLinkClick}
          >
            회원가입
          </a>
        </>
      )}
    </div>
  );
};

export default AuthBtn;