import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const DiscountModal = ({ isOpen, onClose, modalContent }) => {
  const [visible, setVisible] = useState(false); // 모달이 보이는 상태
  const [animating, setAnimating] = useState(false); // 애니메이션 진행 상태

  // 모달 열릴 때와 닫힐 때 애니메이션 처리
  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setTimeout(() => setAnimating(true), 10); // 열릴 때 애니메이션 시작
    } else {
      setAnimating(false);
      setTimeout(() => setVisible(false), 300); // 닫힐 때 300ms 후 완전히 감추기
    }
  }, [isOpen]);

  if (!visible) return null; // visible이 false일 경우 렌더링하지 않음

  // 모달 외부 클릭 시 닫기 기능
  const handleBackgroundClick = (e) => {
    if (e.target.id === 'modal-background') {
      onClose();
    }
  };

  return (
    <div
      id="modal-background"
      className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 transition-opacity duration-300 ${
        animating ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={handleBackgroundClick}
    >
      <div
        className={`bg-white p-[3%] rounded-lg max-w-[810px] w-full relative shadow-lg py-24 transition-transform transition-opacity duration-300 ${
          animating ? 'transform scale-100 opacity-100' : 'transform scale-95 opacity-0'
        }`}
      >
        {/* 닫기 버튼 */}
        <button
          className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 text-2xl"
          onClick={onClose}
        >
          <FaTimes />
        </button>

        {/* 추가적인 모달 내용 */}
        <h4 className="text-lg font-bold text-center mt-4" style={{ whiteSpace: 'pre-line' }}>{modalContent.modalTitle}</h4>
        <p className="text-gray-600 text-center mt-2" style={{ whiteSpace: 'pre-line' }}>{modalContent.modalDetails}</p>

        {/* 링크 추가된 modalExtraInfo */}
        {modalContent.modalExtraInfo && (
          <a
            href={modalContent.modalLink} // modalLink 필드를 추가하여 링크 동적으로 처리
            className="text-[#D00070] font-bold hover:underline text-center block mt-4"
          >
            {modalContent.modalExtraInfo}
          </a>
        )}
      </div>
    </div>
  );
};

export default DiscountModal;