// src/components/company/container/Identity.js

import React from 'react';

const Identity = () => {
  return (
    <div>
      <div className="bg-[#fff] py-24">
        <div className="text-center max-w-[900px] mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-12">
            기초공학교육으로 세상을 이롭게 🚩
          </h2>
          <p className="text-md md:text-lg px-[3%]">
            우리는 <span className='text-[#D00070] font-bold'>기초공학교육</span>과 관련된<br/>다양한 프로젝트에 도전하고 있습니다.<br/><br/>
            초보자든, 고급 학습 그 이상을 준비하고 있든<br/>01CS는 재미있고 간결한 교육을 제공합니다.<br/><br/>
            다양한 공학분야에서<br/>우리만의 교육을 만들어가겠습니다.
          </p>
        </div>
      </div>

      <div className="bg-[#D00070]/30 py-24">
        <div className="text-center max-w-[900px] mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-12">
            적응형 학습을 탑재한 LMS 👾
          </h2>
          <p className="text-md md:text-lg px-[3%]">
          <span className='font-extrabold'>주식회사 슬로프엑셀러리티</span>가<br/>자체적으로 개발하는<span className='text-[#D00070] font-bold'>기초공학교육</span>을 위한<br/> <span className='text-[#D00070] font-bold'>강력한 LMS</span>를 이용해보세요.<br/><br/>
            사용자의 학습경험에 맞춰진<br/>기초공학교육 커리큘럼으로<br/>차근차근 앞으로 나아가보세요.
          </p>
        </div>
      </div>

      <div className="bg-[#fff] py-24">
        <div className="text-center max-w-[900px] mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-12">
            사용자의 학습 데이터 분석 ⚡️
          </h2>
          <p className="text-md md:text-lg px-[3%]">
            세밀한 데이터 분석으로<br/>목표를 향해 달려가는<br/>학습자를 응원하기 위한<br/>다양한 장치를 만들어가겠습니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Identity;