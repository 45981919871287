// src/components/lms/modal/LmsModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const LmsModal = ({ isOpen, onClose, title, content, linkText, linkUrl, customStyles = {} }) => {
  const [visible, setVisible] = useState(false); // 모달이 보이는 상태
  const [animating, setAnimating] = useState(false); // 애니메이션 진행 상태

  // 모달 열릴 때와 닫힐 때 애니메이션 처리
  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setTimeout(() => setAnimating(true), 10); // 열릴 때 애니메이션 시작
    } else {
      setAnimating(false);
      setTimeout(() => setVisible(false), 300); // 닫힐 때 300ms 후 완전히 감추기
    }
  }, [isOpen]);

  if (!visible) return null; // visible이 false일 경우 렌더링하지 않음

  // 모달 외부 클릭 시 닫기 기능
  const handleBackgroundClick = (e) => {
    if (e.target.id === 'modal-background') {
      onClose();
    }
  };

  return (
    <div
      id="modal-background"
      className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 transition-opacity duration-300 ${
        animating ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={handleBackgroundClick}
    >
      <div
        className={`bg-white rounded-lg w-full relative shadow-lg py-6 px-8 transition-transform transition-opacity duration-300 max-w-[800px] ${
          animating ? 'transform scale-100 opacity-100' : 'transform scale-95 opacity-0'
        }`}
        style={customStyles} // 사용자 정의 스타일 적용 가능
      >
        {/* 닫기 버튼 */}
        <button
          className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 text-2xl"
          onClick={onClose}
        >
          <FaTimes />
        </button>

        {/* 모달 제목 */}
        {title && <h2 className="text-2xl font-bold text-center mb-4">{title}</h2>}

        {/* 모달 내용 */}
        {content && (
          <p className="text-gray-600 text-center mb-4" style={{ whiteSpace: 'pre-line' }}>
            {content}
          </p>
        )}

        {/* 링크가 있을 경우 표시 */}
        {linkText && linkUrl && (
          <div className="text-center">
            <a
              href={linkUrl}
              className="text-[#D00070] font-bold hover:underline mt-4 block"
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default LmsModal;