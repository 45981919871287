// src/components/lms/LmsMore.js
import React, { useState } from 'react';
import EmojiGroup from '../../components/lms/container/emoji/LmsEmoji';
import LmsModal from '../../components/lms/modal/LmsModal';
import EndingCredit from '../../components/company/container/ending/EndingCredit';
import Footer from '../../components/footer/Footer';

const LmsMore = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    title: '',
    content: '',
    linkText: '',
    linkUrl: ''
  });

  // 모달 열기 함수
  const openModal = (title, content, linkText, linkUrl) => {
    setModalData({ title, content, linkText, linkUrl });
    setIsModalOpen(true);
  };

  // 모달 닫기 함수
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const bottomItems = [
    { icon: '👬👬👬', label: '그룹 생성 및 관리', link: '#' },
    { icon: '🏃🏻‍♂️‍➡️', label: '코치 신청하기', link: '#' },
    { icon: '🧾', label: '청구 및 구독', link: '#' }
  ];

  return (
    <div>
      <div className="max-w-[1440px] mx-auto flex flex-col items-center pt-24 md:py-24">
        {/* 최상단 제목 */}
        <h1 className="text-5xl md:text-[64px] font-extrabold mb-10">01CS 지원</h1>

        {/* EmojiGroup 컴포넌트 포함, openModal 함수 전달 */}
        <EmojiGroup onEmojiClick={openModal} />

        {/* 하단 타일 형식 그룹 */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[24px] justify-center items-center mt-10 mb-20">
          {bottomItems.map((item, index) => (
            <a
              key={index}
              href={item.link}
              className="flex flex-col justify-center items-center w-[310px] h-[180px] bg-gray-100 hover:bg-gray-200 rounded-lg shadow-lg transition-all"
            >
              <span className="text-3xl">{item.icon}</span>
              <span className="mt-4 text-center text-blue-600" style={{ fontSize: '17px' }}>{item.label}</span>
            </a>
          ))}
        </div>

        {/* LmsModal 컴포넌트 */}
        <LmsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalData.title}
          content={modalData.content}
          linkText={modalData.linkText}
          linkUrl={modalData.linkUrl}
        />

      </div>
      <EndingCredit />
      <Footer />
    </div>
  );
};

export default LmsMore;