import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import csImage from '../../../assets/01cs.png';

const LmsNav = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const menuItems = [
    { icon: '🧐', label: '학습분석', route: '/lms/analysis' },
    { icon: '🧑🏻‍💻', label: '테스트', route: '/lms/test' },
    {
      icon: (
        <img
          src={csImage}
          alt="01CS"
          style={{ width: '23px', height: '25px', marginBottom: '6px' }}
        />
      ),
      label: '홈',
      route: '/lms/home',
    },
    { icon: '🗂️', label: '강의찾기', route: '/lms/class' },
    { icon: '⋯', label: '더보기', route: '/lms/more' },
  ];

  const handleNavigation = (index, route) => {
    setActiveIndex(index);
    navigate(route);
  };

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300 shadow-md z-50">
      <ul className="flex justify-center items-center space-x-8 h-[70px] max-w-[600px] mx-auto">
        {menuItems.map((item, index) => (
          <li
            key={index}
            className={`flex flex-col items-center cursor-pointer ${
              activeIndex === index ? 'text-black' : 'text-gray-500'
            }`}
            onClick={() => handleNavigation(index, item.route)}
          >
            <div className="text-2xl">{item.icon}</div>
            <span className="text-xs mt-1">{item.label}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default LmsNav;