import React from 'react';
import starterImage01 from '../../../../assets/starter01.webp';
import starterImage02 from '../../../../assets/starter02.webp';
import starterImage03 from '../../../../assets/starter03.webp';
import TileContainer from './tileComponents/TileContainer';
import Tile from './tileComponents/Tile';

const StarterContents = () => {
  // 타일 데이터
  const tilesData = [
    {
      title1: '무료 역량테스트',
      title2: '기억력 테스트',
      description: '개발중입니다.',
      img: starterImage01,
      title1Color: '#000',
      title2Color: '#000',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
    },
    {
      title1: '무료 역량테스트',
      title2: '수리력 테스트 LEVEL1',
      description: '개발중입니다.',
      img: starterImage02,
      title1Color: '#D00070',
      title2Color: '#000',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
    },
    {
      title1: '개발자 기초 역량 강화',
      title2: '알고리즘 테스트',
      description: '개발중입니다.',
      img: starterImage03,
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      backgroundColor: '#00069A',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
    },
  ];

  // 각 타일들을 렌더링
  const tiles = tilesData.map((tile, index) => (
    <Tile
      key={index}
      title1={tile.title1}
      title2={tile.title2}
      description={tile.description}
      title1Color={tile.title1Color}
      title2Color={tile.title2Color}
      descriptionColor={tile.descriptionColor}
      img={tile.img}
      backgroundColor={tile.backgroundColor}
      width={tile.width} // Tailwind 너비 적용
      height={tile.height} // Tailwind 높이 적용
      mobileWidth={tile.mobileWidth} // 모바일 너비 적용
      mobileHeight={tile.mobileHeight} // 모바일 높이 적용
    />
  ));

  return (
    <div className="relative w-full py-8">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4">
          <span className="text-[#D00070]">시작하기</span>
          <br />
          두렵다면
        </h2>
      </div>
      <TileContainer tiles={tiles} containerId="starter-tile-scroll" />
    </div>
  );
};

export default StarterContents;