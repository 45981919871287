// src/components/landing/container/emoji/EmojiGroup.js
import React from 'react';
import csImage from '../../../../assets/01cs.png'; // 이미지 경로는 실제 파일 경로에 맞게 설정

const EmojiGroup = ({ onEmojiClick }) => {
  // 각 이모지 항목과 모달에 표시할 내용을 정의합니다.
  const emojis = [
    { 
      emoji: '∞', 
      label: '00MATH', 
      hoverText: '서비스 개발중', 
      modalContent: {
        title: '00MATH',
        content: '수학 기초 과정을 안내합니다.',
        linkText: '자세히 보기',
        // linkUrl: '#'
      }
    },
    { 
      emoji: (
        <img 
          src={csImage} 
          alt="01CS" 
          style={{ width: '40px', height: '40px', marginBottom: '8px' }} 
        />
      ), 
      label: '01CS', 
      hoverText: '10.10 OPEN', 
      modalContent: {
        title: '01CS',
        content: '컴퓨터 과학 기초 교육 과정 안내.',
        linkText: '자세히 보기',
        linkUrl: '/'
      }
    },
    { 
      emoji: '🎈', 
      label: '02Phys', 
      hoverText: '서비스 개발중',
      modalContent: {
        title: '02Phys',
        content: '물리학 기초 교육 과정 안내.',
        linkText: '자세히 보기',
        // linkUrl: '#'
      } 
    },
    { 
      emoji: '🔬', 
      label: '03Chem', 
      hoverText: '서비스 개발중',
      modalContent: {
        title: '03Chem',
        content: '화학 기초 교육 과정 안내.',
        linkText: '자세히 보기',
        // linkUrl: '#'
      } 
    },
    { 
      emoji: '🤖', 
      label: '04Robo', 
      hoverText: '서비스 개발중',
      modalContent: {
        title: '04Robo',
        content: '로봇 공학 기초 교육 과정 안내.',
        linkText: '자세히 보기',
        // linkUrl: '#'
      } 
    },
    { 
      emoji: '💿', 
      label: '08Semi', 
      hoverText: '서비스 개발중',
      modalContent: {
        title: '08Semi',
        content: '반도체 기초 교육 과정 안내.',
        linkText: '자세히 보기',
        // linkUrl: '#'
      } 
    },
    { 
      emoji: '⚡', 
      label: '11Circ', 
      hoverText: '서비스 개발중',
      modalContent: {
        title: '11Circ',
        content: '회로 기초 교육 과정 안내.',
        linkText: '자세히 보기',
        // linkUrl: '#'
      } 
    },
    { 
      emoji: '⚪', 
      label: '19Go', 
      hoverText: '서비스 개발중',
      modalContent: {
        title: '19Go',
        content: '게임 이론 기초 교육 과정 안내.',
        linkText: '자세히 보기',
        // linkUrl: '#'
      } 
    }
  ];

  return (
    <div className="flex justify-center">
      <div className="grid gap-8 bg-white grid-cols-4 grid-rows-2 md:grid-cols-8 md:grid-rows-1 max-w-[1024px] mx-[3%] mt-[15%] md:mt-[10%]">
        {emojis.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col items-center p-4 group rounded-lg transition-all cursor-pointer"
            style={{ boxSizing: 'border-box' }} // box-sizing 설정
            onClick={() => onEmojiClick(item.modalContent.title, item.modalContent.content, item.modalContent.linkText, item.modalContent.linkUrl)} // 이모지 클릭 시 모달 열기
          >
            {/* 마우스 오버 시 나타나는 작은 텍스트 영역 */}
            <div className="absolute -top-10 md:-top-14 transform text-xs md:text-base -mx-4 px-1 py-3 font-bold text-white bg-[#D00070] rounded-lg opacity-0 group-hover:opacity-100">
              {item.hoverText}
            </div>
            {/* 이모지 또는 이미지 */}
            <span className="text-5xl">{item.emoji}</span>
            {/* 텍스트 */}
            <span className="text-gray-600 mt-3">{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmojiGroup;