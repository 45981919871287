import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/private/PrivateRoute';
import Header from './components/header/Header';
import MobileMenu from './components/header/MobileMenu';
import Footer from './components/footer/Footer';
import LandingPage from './pages/LandingPage';
import Popup from './components/landing/popup/Popup';
import FirebaseAuthListener from './FirebaseAuthListener'; // Firebase 상태 리스너 추가

// Category
import CompanyPage from './pages/CompanyPage';
import ClassPage from './pages/ClassPage';
import ZoomPage from './pages/ZoomPage';
import CustomerCenterPage from './pages/CustomerCenterPage';

// Auth
import LoginPage from './pages/auth/LoginPage';
import SignupPage from './pages/auth/SignupPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import FindEmailPage from './pages/auth/FindEmailPage';

// LMS
import LmsLandingPage from './pages/lms/LmsLandingPage';

const App = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const isLmsPage = window.location.pathname.startsWith('/lms');  // 모든 /lms 경로를 LMS로 인식

  return (
    <>
      <FirebaseAuthListener /> {/* Firebase 로그인 상태 리스너 */}
      <Router>
        {!isLmsPage && <Header toggleMobileMenu={toggleMobileMenu} />}
        {!isLmsPage && <MobileMenu isOpen={isMobileMenuOpen} closeMenu={closeMobileMenu} />}
        {!isLmsPage && <Popup />}

        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* Category Routes */}
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/class" element={<PrivateRoute element={ClassPage} />} />
          <Route path="/zoom" element={<PrivateRoute element={ZoomPage} />} />
          <Route path="/customer" element={<CustomerCenterPage />} />

          {/* Auth Routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/find-email" element={<FindEmailPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />

          {/* LMS Routes */}
          <Route path="/lms/*" element={<PrivateRoute element={LmsLandingPage} />} /> 
        </Routes>

        {!isLmsPage && <Footer />}
      </Router>
    </>
  );
};

export default App;