import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBg4SMN_M-fajDEwHMPYD9topn-fgghwwM",
  authDomain: "cs01-f664f.firebaseapp.com",
  projectId: "cs01-f664f",
  storageBucket: "cs01-f664f.appspot.com",
  messagingSenderId: "60395917627",
  appId: "1:60395917627:web:a142284b23b2d038c4271e",
  measurementId: "G-G4SQJ255NL"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app); // 추가
const analytics = getAnalytics(app);

export { app, db, auth, functions, analytics };
