import React from 'react';

const Tile = ({
  title1,
  title2,
  description,
  title1Color,
  title2Color,
  descriptionColor,
  img,
  backgroundColor,
  width, // Tailwind width class
  height, // Tailwind height class
  mobileWidth, // 모바일 width class
  mobileHeight // 모바일 height class
}) => {
  return (
    <div
      className={`rounded-2xl shadow-lg mx-4 flex-shrink-0 transform transition-transform duration-300 hover:scale-105 relative ${width} ${height} ${mobileWidth} ${mobileHeight}`}
      style={{
        backgroundColor: backgroundColor || '#f0f0f0',
        backgroundImage: img ? `url(${img})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="p-6">
        <h3 className="text-sm font-bold" style={{ color: title1Color }}>{title1}</h3>
        <h3 className="text-xl font-extrabold" style={{ color: title2Color }}>{title2}</h3>
        <p className="font-bold mt-[330px] md:mt-[375px]" style={{ color: descriptionColor }}>{description}</p>
      </div>
    </div>
  );
};

export default Tile;