import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LmsNav from '../../components/lms/nav/LmsNav';
import LmsHome from './LmsHome';
import LmsTest from './LmsTest';
import LmsAnalysis from './LmsAnalysis';
import LmsClass from './LmsClass';
import LmsMore from './LmsMore';

const LmsLandingPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1"> {/* Nav가 하단에 고정되도록 여유 공간 확보 */}
        <Routes>
          <Route path="home" element={<LmsHome />} />
          <Route path="test" element={<LmsTest />} />
          <Route path="analysis" element={<LmsAnalysis />} />
          <Route path="class" element={<LmsClass />} />
          <Route path="more" element={<LmsMore />} />
          {/* 기본적으로 /lms로 접근 시 home으로 리다이렉트 */}
          <Route path="/" element={<Navigate to="home" />} />
        </Routes>
      </div>
      <LmsNav /> {/* 항상 하단에 고정된 네비게이션 */}
    </div>
  );
};

export default LmsLandingPage;