import React from 'react';
import artworkImage from '../../assets/artworkImage.webp';

const LmsClass = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-white relative overflow-hidden">
      {/* 조명 효과 */}
      <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
        <div
          className="w-full h-full bg-gradient-radial from-white via-transparent to-transparent opacity-80"
          style={{
            backgroundPosition: 'center',
            backgroundSize: '500px 500px',
          }}
        ></div>
      </div>

      {/* 작품 영역과 라벨 */}
      <div className="relative z-10 flex flex-col items-center p-2 md:p-12 mb-20">
        {/* 작품 이미지 */}
        <div className="w-[90%] md:w-[60%] h-auto bg-white shadow-2xl">
          <img
            src={artworkImage}
            alt="Artwork"
            className="w-full h-full object-cover"
          />
        </div>

        {/* 작품 라벨 */}
        <div className="mt-4">
          <div className="bg-white shadow-lg rounded-lg p-4 max-w-xs">
            {/* 타이틀 */}
            <h2 className="text-lg font-extrabold text-gray-800">
              Class
            </h2>
            <div className="w-60 border-b-2 border-gray-800 mt-1 mb-3"></div>
            {/* 작가 소개 */}
            <div className="bg-black font-extrabold text-white text-sm p-2 rounded text-center">
              <span className='text-[#D00070]'>SlopeX</span>elerity
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LmsClass;