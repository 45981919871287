// Main.js
import React from 'react';
import { useSelector } from 'react-redux';
import EmojiGroup from '../emoji/EmojiGroup';
import NaverBlog from '../../../../assets/naver.png'; // 이미지 경로를 수정하세요

const Main = ({ buttonStyle }) => {
  const user = useSelector((state) => state.auth.user); // 이제 state.auth.user는 직렬화된 데이터

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center items-center px-[8%] mt-[15%]">
        <div className="max-w-[1440px] w-full flex flex-col md:flex-row justify-between">
          {/* 왼쪽 텍스트 */}
          <div className="flex flex-col mb-8 mt-24 md:mt-0 md:mb-0 md:mr-4">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900">
              <span className="text-2xl md:text-4xl mb-2 block">비전공자, 청소년도 가능한</span>
              <span className="text-[#D00070]">01CS의 기초공학교육</span>🚩
            </h1>
            <h2 className="text-xl md:text-xl font-bold text-gray-900 mt-4 md:mt-6">
              To provide foundational engineering education.{' '}
              <span className="inline-block">
                <span role="img" aria-label="gear">⚙️</span>
              </span>
            </h2>

            {/* 로그인된 사용자에게만 버튼 표시 */}
            {user && (
              <a
                href="/lms"
                target="_blank"
                rel="noopener noreferrer"
                className={`bg-[#D00070] text-[#fff] border-[#D00070] text-center font-extrabold mt-6 py-2 px-5 rounded-full border-2 border-transparent transition duration-300 ${buttonStyle}`}
              >
                LMS 개발중입니다.
              </a>
            )}
          </div>

          {/* 오른쪽 텍스트 */}
          <div className="flex flex-col mt-8 md:mt-0 text-right md:text-right">
            <p className="text-gray-600 mb-1">13세 이상부터</p>
            <a href="##" className="text-[#D00070] font-bold hover:underline mb-4 md:mb-6">세상을 변화시키는 개발자로 🧑🏻‍💻</a>

            <p className="text-gray-600 mb-1">전문 코치를 찾아보세요</p>
            <a href="##" className="text-[#D00070] font-bold hover:underline mb-4 md:mb-6">나에게 맞는 코치 찾기 👻</a>

            <p className="text-gray-600 mb-1">우리의 이야기를 담은</p>
            <a
              href="https://blog.naver.com/slopexcelerity"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#2DB400] font-bold hover:underline flex items-center justify-end"
            >
              SlopeXcelerity 블로그
              <img
                src={NaverBlog}
                alt="데이터 분석"
                className="w-4 h-4 ml-1"
              />
            </a>
          </div>
        </div>
      </div>

      {/* EmojiGroup 컴포넌트 추가 */}
      <EmojiGroup />
    </div>
  );
};

export default Main;