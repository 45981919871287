// src/pages/LoginPage.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../Firebase';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // 이메일 유효성 검사 함수
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // 이메일 입력 핸들러
  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsEmailValid(validateEmail(emailInput));
  };

  // 패스워드 입력 핸들러
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // 로그인 처리 함수
  const handleLogin = async (event) => {
    event.preventDefault();
    if (!isEmailValid) {
      setError('유효한 이메일 주소를 입력하세요.');
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/'); // 로그인 후 리디렉트될 경로
    } catch (error) {
      // 오류에 따른 적절한 메시지 표시
      if (error.code === 'auth/invalid-email') {
        setError('유효하지 않은 이메일 형식입니다.');
      } else if (error.code === 'auth/user-not-found') {
        setError('등록되지 않은 이메일입니다.');
      } else if (error.code === 'auth/wrong-password') {
        setError('잘못된 비밀번호입니다.');
      } else {
        setError('로그인에 실패했습니다.');
      }
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="p-6 bg-white rounded-lg max-w-md w-full mx-4">
        <h2 className="text-2xl font-bold mb-10 text-center">공학의 기초부터 탄탄하게 🏗️</h2>

        <form className="space-y-6" onSubmit={handleLogin}>
        <div>
          <label
            htmlFor="email"
            className="text-sm font-medium text-[#D00070] block mb-2 border-b-2 border-transparent focus-within:border-[#D00070]"
          >
            이메일
          </label>
          <input
            type="email"
            id="email"
            className="w-full p-2 border border-[#D00070] rounded-md text-[#D00070] focus:outline-none focus:border-[#D00070] placeholder-[#D00070] focus:bg-[#D00070]/10"
            value={email}
            onChange={handleEmailChange}
          />
        </div>

        <div>
          <label
            htmlFor="password"
            className="text-sm font-medium text-[#D00070] block mb-2 border-b-2 border-transparent focus-within:border-[#D00070]"
          >
            패스워드
          </label>
          <input
            type="password"
            id="password"
            className="w-full p-2 border border-[#D00070] rounded-md text-[#D00070] focus:outline-none focus:border-[#D00070] placeholder-[#D00070] focus:bg-[#D00070]/10"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>

          <button 
            type="submit" 
            className={`w-full p-2 rounded-md text-white font-extrabold ${
              isEmailValid 
                ? 'bg-[#D00070] hover:bg-[#D00070] hover:opacity-90'  // hover 상태에서 투명도 적용
                : 'bg-[#D00070] opacity-40 cursor-not-allowed'
            }`}
            disabled={!isEmailValid}
          >
            로 그 인
          </button>

          {error && <p className="text-red-500 text-sm">{error}</p>}

          <div className="flex justify-between text-sm text-[#D00070] hover:opacity-90">
            <Link to="/find-email">아이디 찾기</Link>
            <Link to="/reset-password">비밀번호 찾기</Link>
            <Link to="/signup">회원가입</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;