import React, { useState, useEffect } from 'react';
import tileBackground01 from '../../../../assets/infoContents01.png';
import tileBackground02 from '../../../../assets/infoContents02.png';
import tileBackground03 from '../../../../assets/infoContents03.png';

const InfoContents = () => {
  const tiles = [
    {
      title: '01CS 코치',
      description: '온라인으로 진행하는 공학 기초 교육 코치와 함께 공부하고 방향을 잡아가세요.',
      subDescription: '어디서부터 시작해야할지, 어떻게 공부해야할지 모르겠다면. 집중적인 코치를 받으면서 학습 습관을 길러보세요.',
      backgroundImage: tileBackground01,
    },
    {
      title: '원하는 전공을 선택해서 공부할 수 있습니다.',
      description: '당연히 기초 공학 교육도 제공합니다.',
      backgroundImage: tileBackground03,
    },
    {
      title: '교육 프로그램 안내',
      description: '다양한 교육 프로그램을 통해 전문성을 높이세요.',
      subDescription: '기초부터 고급 과정까지, 단계별로 맞춤형 교육을 제공합니다.',
      backgroundImage: tileBackground02,
    },
    {
      title: '커리큘럼 맞춤 구성',
      description: '필요한 부분만 선택해서 학습할 수 있는 커리큘럼 제공.',
      backgroundImage: tileBackground01,
    },
  ];

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [showButtons, setShowButtons] = useState(false);

  const handleScroll = () => {
    const scrollContainer = document.getElementById('info-tile-scroll');
    const scrollLeft = scrollContainer.scrollLeft;
    const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
  
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < maxScrollLeft);
  };

  useEffect(() => {
    const scrollContainer = document.getElementById('info-tile-scroll');
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollLeft = () => {
    document.getElementById('info-tile-scroll').scrollBy({ left: -490, behavior: 'smooth' });
  };

  const scrollRight = () => {
    document.getElementById('info-tile-scroll').scrollBy({ left: 490, behavior: 'smooth' });
  };

  return (
    <div className="relative w-full py-8">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4">
          <span className="text-[#D00070]">학습자 맞춤형</span><br />기초 공학 교육과정 운영
        </h2>
      </div>

      <div
        className="relative"
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        {/* 좌우 스크롤 버튼 */}
        <button
          onClick={scrollLeft}
          className={`absolute top-40 left-5 z-10 -translate-y-1/2 w-16 h-16 flex items-center justify-center bg-[#fff]/60 hover:bg-[#fff]/80 rounded-full shadow-lg hidden md:block`}
          disabled={!canScrollLeft}
          style={{
            opacity: showButtons && canScrollLeft ? 1 : 0,
            transform: showButtons && canScrollLeft ? 'scale(1)' : 'scale(0.9)',
            transition: 'opacity 0.8s ease-in-out, transform 0.5s ease-in-out',
          }}
        >
          <span className="text-3xl text-black" style={{ fontWeight: '900' }}>
            〈
          </span>
        </button>

        <button
          onClick={scrollRight}
          className={`absolute top-40 right-5 z-10 -translate-y-1/2 w-16 h-16 flex items-center justify-center bg-[#fff]/60 hover:bg-[#fff]/80 rounded-full shadow-lg hidden md:block`}
          disabled={!canScrollRight}
          style={{
            opacity: showButtons && canScrollRight ? 1 : 0,
            transform: showButtons && canScrollRight ? 'scale(1)' : 'scale(0.9)',
            transition: 'opacity 0.8s ease-in-out, transform 0.5s ease-in-out',
          }}
        >
          <span className="text-3xl text-black ml-1" style={{ fontWeight: '900' }}>
            〉
          </span>
        </button>

        {/* 스크롤 가능한 타일 컨테이너 */}
        <div id="info-tile-scroll" className="flex justify-start overflow-x-auto no-scrollbar w-full px-2 py-10">
          {tiles.map((tile, index) => (
            <div
              key={index}
              className="flex-shrink-0 mx-4 rounded-2xl shadow-lg transform transition-transform duration-300 hover:scale-105"
              style={{
                width: window.innerWidth >= 900 ? '560px' : '350px', // 반응형 너비
                height: '300px', // 고정된 높이
                backgroundImage: `url(${tile.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="p-4 rounded-xl">
                <h3 className="text-lg font-bold mb-2">{tile.title}</h3>
                <p className="text-sm text-gray-700 mb-4">{tile.description}</p>
                {tile.subDescription && (
                  <p className="text-xs text-gray-500">{tile.subDescription}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfoContents;